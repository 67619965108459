import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { chunk } from "lodash";
import OverLayImage from './OverLayImage';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    gridListOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
    },
    gridListTile: {
        padding: theme.spacing(0.5),
        transition: 'opacity 0.3s',
    },
    gridImg: {
        width: "100%",
        height: 'auto',
        filter: 'grayscale(100%)',
        transition: 'opacity 0.1s ease'
    },
    overlayImg_outer: {
        width: '100%',
        position: 'relative',
        '&:hover>*, &:hover>*>*': {
            opacity: 1,
        },
    },
    overlayImg_inner: {
        width: "100%",
        height: 'auto'
    },
    overlayImg_inner_fg: {},
    overlayImg_inner_bg: {
        opacity: 0,
        transition: 'opacity 0.1s ease',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
    }
}));

function Manga(props) {
    const classes = useStyles();
    if (props.mangasData === undefined) {
        props.history.replace(`/${props.match.params.Lang}`);
    }
    const data = props.mangasData ? props.mangasData.map((e, idx) => { return { idx: idx, ...e } }).reverse() : null;
    const [coverWeight, setCoverWeight] = useState([]);
    const [pageReady, setPageReady] = useState(false);
    const [rowReady, setRowReady] = useState({});
    useEffect(() => { props.mangasData && setCoverWeight(props.mangasData.map(e => 0)) }, [props.mangasData]);
    return (
        <Fragment>
            <Helmet>
                <title>Comic - Ovumie</title>
                <meta name="title" content="Comic - Ovumie" />
                <meta property="og:title" content="Comic - Ovumie" />
                <meta property="twitter:title" content="Comic - Ovumie" />
            </Helmet>
            {!pageReady && <div style={{ minHeight: "calc(100vh - 345.533px)", textAlign: 'center', margin: 'auto' }}><CircularProgress /></div>}
            {data &&
                <Fragment>
                    <Box>
                        <Box key={data[0].series + data[0].episode} className={`${classes.gridListTile} gridListTile`} style={{ opacity: pageReady ? 1 : 0 }}>
                            <Link to={`/${props.match.params.Lang}/manga/${data[0].idx}`}>
                                <OverLayImage
                                    fg={data[0].grayscaleCover}
                                    bg={data[0].fullColorCover}
                                />
                            </Link>
                        </Box>
                    </Box>
                </Fragment>
            }
            {data && chunk(data.slice(1), 2).map((data, iOuter) =>
                <Fragment>
                    <Box display="flex" flexWrap="nowrap">
                        {data.map((tile, iInter, _chunk) => {
                            return (
                                <Box key={tile.series + tile.episode} className={`${classes.gridListTile} gridListTile`} flexShrink={coverWeight[tile.idx]} flexGrow={coverWeight[tile.idx]} flexBasis={0}
                                    style={{ opacity: pageReady ? 1 : 0 }}>
                                    <Link to={`/${props.match.params.Lang}/manga/${tile.idx}`}>
                                        <OverLayImage
                                            fg={tile.grayscaleCover}
                                            bg={tile.fullColorCover}
                                            onLoad={({ target: img }) => {
                                                setCoverWeight(coverWeight.map((e, idx) => idx === (tile.idx) ? (10000 * img.naturalWidth / img.naturalHeight) : e));
                                                if (coverWeight.filter(e => e === 0).length < 3) {
                                                    setPageReady(true);
                                                }
                                            }}
                                        />
                                    </Link>
                                </Box>
                            )
                        })}
                    </Box>
                </Fragment>)}
        </Fragment>
    );
}

export default Manga;
