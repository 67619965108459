import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Chip } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ReactCursorPosition from 'react-cursor-position';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    boothImg: {
        border: "0px solid black",
        borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
        width: "100%",
        height: '100%',
        transition: "opacity 0.2s",
        opacity: 1,
        objectFit: 'cover'
    },
    boothImgHidden: {
        opacity: 0
    },
    boothItem: {
        border: "0px solid black",
        borderRadius: theme.spacing(2),
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.15)",
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    boothCover: {
        border: "0px solid black",
        borderRadius: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 0`,
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    boothText: {
        padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`
    },
    boothTitle: {
        fontWeight: 'bold',
        fontFamily: "'筑紫B丸ゴシック ボールド', 'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif",
        fontSize: '21px'
    },
    boothTag: {
        margin: theme.spacing(0.5)
    },
    boothDescription: {
        color: "#757575",
        fontFamily: "'筑紫B丸ゴシック ボールド', 'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif",
        fontSize: '19px'
    },
    bannerContent: {
        padding: theme.spacing(2),
        textAlign: 'center',
        fontWeight: 'bold'
    }
}));

function Booth(props) {
    const classes = useStyles();
    if (props.boothData === undefined) {
        props.history.replace(`/${props.match.params.Lang}`);
        return (<Fragment />);
    }
    const data = props.boothData ? props.boothData.map((e, idx) => { return { idx: idx, ...e } }) : null;
    const bannerData = props.boothBanner ? props.boothBanner : null;
    return (
        <Fragment>
            <Helmet>
                <title>Shop* - Ovumie</title>
                <meta name="title" content="Shop* - Ovumie" />
                <meta property="og:title" content="Shop* - Ovumie" />
                <meta property="twitter:title" content="Shop* - Ovumie" />
            </Helmet>
            {data && bannerData && <Grid container justifyContent='center' alignItems='center' style={{ border: 'solid #333 4px', marginBottom: '16px', borderRadius: '4px' }}>
                <Grid item style={{ flexGrow: 1, flexShrink: 1, flexBasis: '120px' }}>
                    <img style={{ height: 'auto', width: '100%', verticalAlign: 'middle' }} src='/Asset/Booth_banner.png' />
                </Grid>
                <Grid item style={{
                    flexGrow: 2, flexShrink: 1, flexBasis: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ReactMarkdown linkTarget='_blank' className={`bannerContent mdContent ${classes.bannerContent}`} source={bannerData} />
                </Grid>
            </Grid>}
            <Grid container spacing={4}>
                {data && data.map((tile, iOuter) =>
                    <Fragment>
                        <Grid item sm={12} lg={6} style={{paddingLeft: 22, paddingRight: 22}}>
                            <DynamicLink link={tile.link}>
                                <Box className={classes.boothItem}>
                                    <div className={classes.boothCover}>
                                        <ReactCursorPosition style={{height: '100%'}}>
                                            <SlidingImage
                                                imgs={tile.imgs}
                                                alt={`${tile.name}`}
                                                className={classes.boothImg}
                                                ActivateClassName={classes.boothImgHidden}
                                            />
                                        </ReactCursorPosition>
                                    </div>
                                    <div className={classes.boothText}>
                                        <Typography className={classes.boothTitle}>{tile.name}</Typography>
                                        <Typography className={classes.boothDescription}>{tile.description}</Typography>
                                        {tile.tags.map(tag => <Chip label={`${tag}`} className={classes.boothTag} />)}
                                    </div>
                                </Box>
                            </DynamicLink>
                        </Grid>
                    </Fragment>)}
                {!data && <div style={{ minHeight: "calc(100vh - 345.533px - 48px)" }}>
                </div>}
            </Grid>
        </Fragment>
    );
}

function SlidingImage(props) {
    var imgNumber = 0;
    if (props.isActive) {
        imgNumber = Math.floor((props.imgs.length) * props.position.x / props.elementDimensions.width)
    }
    return (
        <div style={{ position: "relative", height: '100%' }}>
            <img
                src={props.imgs[0]}
                alt={`${props.alt}`}
                className={`${props.className} ${props.isActive ? props.ActivateClassName : ""}`}
            />
            {props.imgs.map((img, idx) =>
                <div style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    backgroundImage: `url(${props.imgs[idx]})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                }}
                    className={`${props.className} ${imgNumber === idx ? "" : props.ActivateClassName}`}></div>
            )
            }
        </div>
    )
}

function DynamicLink(props) {
    const { link, children, ...others } = props;
    if (link) {
        return (<Fragment>
            <a href={link} target="_blank">
                {children}
            </a>
        </Fragment>)
    } else {
        return (<Fragment>
            {children}
        </Fragment>)
    }
}

export default Booth;
