import { Typography, Container, Box, Button } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { MdArrowBack } from 'react-icons/md';
import AdaptiveImageSrcSet from "./AdaptiveImageSrcSet";
import ReactPlayer from "react-player";

const useStyles = makeStyles(theme => ({
    maxWidth700: {
        maxWidth: '700px'
    },
    pageContent: {
        '&>p': {
            marginTop: '1em'
        },
        margin: '32px 16px'
    },
    pageImgs: {
        '& picture, & img': {
            width: '100%',
        }
    },
    pageVideos: {
        '& > *': {
            width: '100%'
        }
    }
}));
function MiscReader(props) {
    const classes = useStyles();
    const data = props.miscData;

    useEffect(_ => {
        window.scrollTo(0, 0);
    }, [props.history.location.pathname]);

    const page_key = props.match.params.page_key;
    const page = data && data.find(o => o.id == page_key);
    if (data && !page) {
        props.history.replace(`/${props.match.params.Lang}`);
        return (<Fragment />);
    }

    return page && (<Fragment>
        <Helmet>
            <title>{`${page.name} - Ovumie`}</title>
            <meta name="title" content={`${page.name} - Ovumie`} />
            <meta property="og:title" content={`${page.name} - Ovumie`} />
            <meta property="twitter:title" content={`${page.name} - Ovumie`} />
        </Helmet>
        <Container className={`${classes.maxWidth700}`}>
            <Box><Link to={`/${props.match.params.Lang}/misc/`}><Button><MdArrowBack />&nbsp;Back to Menu</Button></Link></Box>
            <Typography variant="h3" style={{ fontFamily: "'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif", fontSize: "36px", fontWeight: 'bold', textAlign: "center", lineHeight: "150%", marginBottom: '16px' }}>
                {page.name}
            </Typography>
            <div className={classes.pageContent}>
                {page.description && page.description.map(line => (<Typography>{line}</Typography>))}
            </div>
            <div className={classes.pageVideos}>
                {page.videos && page.videos.map(video => (<ReactPlayer url={video} />))}
            </div>
            <div className={classes.pageImgs}>
                {page.imgs && page.imgs.map(img => (<AdaptiveImageSrcSet src={img} />))}
            </div>
            <Box><Link to={`/${props.match.params.Lang}/misc/`}><Button><MdArrowBack />&nbsp;Back to Menu</Button></Link></Box>
        </Container>
    </Fragment >);
}

export default MiscReader;