import React from 'react';

function AdaptiveImageSrcSet(props) {
    const { src, style, className, ...others } = props;
    const advanceImageFormats = ['avif', 'heic', 'heif', 'webp']
    let originSrc = null;
    let fileExtension = src.split('.').pop();
    if (advanceImageFormats.indexOf(fileExtension) >= 0) {
        originSrc = src.replace(`.${fileExtension}`, '.jpg');
    }

    if (originSrc) {
        return (<picture className={className} style={style} {...others}>
            <source srcSet={src} type={`image/${fileExtension}`} />
            <source srcSet={originSrc} type='image/jpeg' />
            <img src={originSrc} className={className} style={style} {...others} />
        </picture>)
    } else {
        return (<img src={src} className={className} style={style} {...others} />);
    }
}

export default AdaptiveImageSrcSet;