import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import { Route, Link } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Drawer, Fade, List, ListItem, ListItemText, Paper, Popover } from '@material-ui/core';
import { Container, Tab, Tabs, MuiThemeProvider, Grid, Tooltip } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { createMuiTheme } from '@material-ui/core/styles';
import { FaTwitter, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { MdMenu, MdClose, MdMailOutline, MdCheckCircle } from 'react-icons/md';
import { FiFacebook, FiInstagram, FiTwitter } from 'react-icons/fi';
import { withRouter } from "react-router-dom";
import ReactGA from 'react-ga';
import './App.css';
import './Layout.css';
import O_SVG from './title-svgs/O.svg';
import V_SVG from './title-svgs/V.svg';
import U_SVG from './title-svgs/U.svg';
import M_SVG from './title-svgs/M.svg';
import I_SVG from './title-svgs/I.svg';
import E_SVG from './title-svgs/E.svg';
import SVGIcons from './icon-svgs/Icons';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ce1b65'
    },
    secondary: {
      main: "#297b83",
      light: "#4fbece"
    },
    grey: {
      main: '#212121'
    }
  },
  overrides: {
    MuiTab: {
      root: {
        zIndex: 10,
      },
    }
  }
});
const useStyles = makeStyles(_theme => ({
  root: {
    background: 'white'
  },
  avator: {
    borderRadius: "50%",
    width: '1em',
    heght: 'auto'
  },
  links: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    '&:hover': {
      color: theme.palette.secondary.light
    },
    margin: '5px',
    transition: "color 0.2s"
  },
  socialMediaLinks: {
    color: 'white',
    fontSize: 16,
    display: 'inline',
  },
  socialMediaContainer: {
    display: 'grid',
    gridGap: '0.6em',
    gridTemplateColumns: 'repeat(4, min-content)',
    justifyContent: 'end',
  },
  socialMediaOuters: {
    color: 'white',
    fontSize: 16,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    '&>*': {
      opacity: 0.7,
      transition: "opacity 0.5s",
      '&:hover': {
        opacity: 1
      }
    },
    '& svg': {
      width: 16,
      height: 20,
      '& path': {
        fill: 'white',
        strokeWidth: 20,
        stroke: 'white'
      }
    }
  },
  topCard: {
    textAlign: 'center',
    background: '#212121',
    backgroundImage: 'url(/Asset/topCardBackground.png)',
    margin: 0,
    padding: 0,
    width: '100%',
    maxWidth: "none",
    color: "white",
    //animation: 'movingBackground 5s linear infinite',
    position: 'relative'
  },
  topCardBackgroundCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '265.3px',
  },
  titleCard: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  title: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  titleCharacter: {
    flexBasis: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  titleCharacterImage: {
    width: '100%',
    height: 'auto'
  },
  search: {
    position: 'relative',
    backgroundColor: fade(theme.palette.common.white, 0),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.05),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
    height: '100%'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: "transparent",
    minHeight: "calc(100vh - 345.533px - 35.0167px)"
  },
  indicator: {
    backgroundColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(2)
  },
  selectedTab: {
    color: theme.palette.secondary.light,
  },
  tabs: {
    width: '100%',
  },
  tabsContainer: {
    justifyContent: 'space-between'
  },
  tab: {
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: '筑紫B丸ゴシック ボールド',
    transition: 'opacity 0.5s',
    paddingBottom: '18px',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: '0px',
    letterSpacing: '2px',
    '&:hover': {
      opacity: 1,
    }
  },
  tabWrapper: {
    whiteSpace: 'nowrap'
  },
  maxWidth700: {
    maxWidth: '700px'
  },
  footer: {
    textAlign: 'center',
    background: '#212121',
    backgroundImage: 'url(/Asset/topCardBackground.png)',
    margin: 0,
    padding: 0,
    width: '100%',
    maxWidth: "none",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  footerBackgroundCanvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '83.0167px',
  },
  footerTitle: {
    textAlign: 'right',
    textAlignLast: 'right',
  },
  tooltips: {
    marginBottom: '0px',
  },
  menuSVGIcon: {
    width: 24,
    height: 24,
    '& path': {
      fill: 'currentColor',
      stroke: 'currentColor'
    }
  },
  HamburgerMenuItem: {
    fontFamily: '筑紫B丸ゴシック ボールド',
  }
}));


function MailAddressButton(props) {
  const address = props.address || '';
  const [popped, setPopped] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const copyToClipboard = (_content) => {
    navigator.clipboard.writeText(_content).then(() => {
      setPopped(true);
    }, () => { });
  }
  return (<Fragment>
    <span onClick={(evt) => {
      setAnchorEl(evt.target);
      copyToClipboard(address);
    }}
      style={{ display: 'inline', cursor: 'pointer' }}><SVGIcons.MessageIcon /></span>
    <Popover
      open={popped}
      anchorEl={anchorEl}
      onClose={() => { setPopped(false) }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Paper>
        <Typography sx={{ p: 2 }} style={{ padding: '0.5em 1em' }} ><MdCheckCircle /> Copied!</Typography>
      </Paper>
    </Popover>
  </Fragment>)
}

function Layout(props) {
  const classes = useStyles(theme);
  const [aboutData, setAboutData] = useState(null);
  const [miscData, setMiscData] = useState(null);
  const [boothData, setBoothData] = useState(null);
  const [boothBanner, setBoothBanner] = useState(null);
  const [illustrationData, setIllustrationData] = useState(null);
  const [mangasData, setMangasData] = useState(null);
  const [contactData, setContactData] = useState(null);
  /*useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [props.history.location.pathname]);*/
  useEffect(() => {
    fetch(`/Pages/About_${props.match.params.Lang}.md`, { cache: "no-cache" })
      .then(response => response.text())
      .then(_data => setAboutData(_data));
    fetch(`/Pages/Misc_${props.match.params.Lang}.json`, { cache: "no-cache" })
      .then(response => response.json())
      .then(_data => setMiscData(_data))
      .catch(error => setMiscData(undefined));
    fetch(`/Pages/Booth_${props.match.params.Lang}.json`, { cache: "no-cache" })
      .then(response => response.json())
      .then(_data => setBoothData(_data))
      .catch(error => setBoothData(undefined));
    fetch(`/Pages/BoothBanner_${props.match.params.Lang}.md`, { cache: "no-cache" })
      .then(response => response.text())
      .then(_data => setBoothBanner(_data));
    fetch(`/Pages/Illustration_${props.match.params.Lang}.json`, { cache: "no-cache" })
      .then(response => response.json())
      .then(_data => setIllustrationData(_data))
      .catch(error => setIllustrationData(undefined));
    fetch(`/Pages/Mangas_${props.match.params.Lang}.json`, { cache: "no-cache" })
      .then(response => response.json())
      .then(_data => setMangasData(_data))
      .catch(error => setMangasData(undefined));
    fetch(`/Pages/Contact_${props.match.params.Lang}.md`, { cache: "no-cache" })
      .then(response => response.text())
      .then(_data => setContactData(_data));
  }, []);
  useEffect(() => {
    ReactGA.initialize('UA-151742065-1')
  }, []);
  useEffect(() => {
    setTimeout(() => ReactGA.pageview(props.history.location.pathname), 200);
  }, [props.history.location.pathname]);
  const lang = props.match.params.Lang;
  const menuItems = [
    { 'text': 'About', 'to': `/${lang}/about` },
    { 'text': 'Drawing', 'to': `/${lang}/illustation` },
    { 'text': 'Comics', 'to': `/${lang}/manga` },
    { 'text': 'Others', 'to': `/${lang}/misc`, 'disabled': !miscData },
    { 'text': 'Shop*', 'to': `/${lang}/booth`, 'disabled': !boothData },
    { 'text': '/' },
    { 'text': 'Contact', 'to': `/${lang}/contact` },
  ];
  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={theme}>
        <div className={classes.root}>
          <Container className={classes.topCard} >
            <Route path="/:Lang/" render={(_props) => <HamburgerMenu boothData={boothData} miscData={miscData} aboutData={aboutData} illustrationData={illustrationData} mangasData={mangasData} menuItems={menuItems} {..._props} />} />
            <Container className={`${classes.titleCard} ${classes.maxWidth700}`} style={{ zIndex: 1, position: 'relative' }}>
              <Link to="/">
                <Grid container className={classes.title}>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 41.995, flexShrink: 0, maxWidth: 60 * 0.75 }} className={classes.titleCharacter}><img src={O_SVG} className={classes.titleCharacterImage} /></Grid>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 41.575, flexShrink: 0, maxWidth: 59.399 * 0.75 }} className={classes.titleCharacter}><img src={V_SVG} className={classes.titleCharacterImage} /></Grid>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 33.175, flexShrink: 0, maxWidth: 47.398 * 0.75 }} className={classes.titleCharacter}><img src={U_SVG} className={classes.titleCharacterImage} /></Grid>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 41.935, flexShrink: 0, maxWidth: 59.914 * 0.75 }} className={classes.titleCharacter}><img src={M_SVG} className={classes.titleCharacterImage} /></Grid>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 13.559, flexShrink: 0, maxWidth: 19.372 * 0.75 }} className={classes.titleCharacter}><img src={I_SVG} className={classes.titleCharacterImage} /></Grid>
                  <Grid item zeroMinWidth style={{ flexBasic: 1, flexGrow: 23.997, flexShrink: 0, maxWidth: 34.285 * 0.75 }} className={classes.titleCharacter}><img src={E_SVG} className={classes.titleCharacterImage} /></Grid>
                </Grid>
              </Link>
              <div className={classes.socialMediaContainer}>
                <Typography className={classes.socialMediaOuters}><MailAddressButton address='ovumie@gmail.com' /> <a href="mailto:ovumie@gmail.com" className={classes.socialMediaLinks} style={{ marginTop: "-4px", marginLeft: "4px", fontFamily: '筑紫B丸ゴシック ボールド' }}>ovumie@gmail.com</a></Typography>
                <Typography className={classes.socialMediaOuters}><a href="https://www.facebook.com/huihui2017/" className={classes.socialMediaLinks}><SVGIcons.FacebookIcon /></a></Typography>
                <Typography className={classes.socialMediaOuters}><a href="https://www.instagram.com/ovumie/" className={classes.socialMediaLinks}><SVGIcons.InstagramIcon /></a></Typography>
                <Typography className={classes.socialMediaOuters}><a href="https://twitter.com/ovumie" className={classes.socialMediaLinks}><SVGIcons.TwitterIcon /></a></Typography>
              </div>
            </Container>
            <Container maxWidth='md'>
              <Route path="/:Lang/" render={(_props) => <Menu boothData={boothData} miscData={miscData} aboutData={aboutData} illustrationData={illustrationData} mangasData={mangasData} menuItems={menuItems} {..._props} />} />
            </Container>
          </Container>
          <Container className={`${classes.mainContent}`} maxWidth='md'>
            {React.Children.map(props.children, child => React.cloneElement(child, { aboutData: aboutData, miscData: miscData, boothData: boothData, boothBanner: boothBanner, illustrationData: illustrationData, mangasData: mangasData, contactData: contactData }))}
          </Container>
          <div>
            <Container className={`${classes.footer}`} maxWidth='md'>
              <Container maxWidth='md'>
                <Grid container justify="flex-end" alignItems="flex-start" spacing={2}>
                  <Grid item>
                    <Typography variant="h6" className={`${classes.title} ${classes.footerTitle}`}>© OVUMIE
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </div>
        </div>
      </MuiThemeProvider>
    </Fragment>
  )
}

function MenuItem(props) {
  const classes = useStyles(theme);
  const text = props.text || '';
  const to = props.to;
  const disabled = props.disabled;
  const inner = (<Tab classes={{ root: classes.tab, selected: classes.selectedTab, wrapper: classes.tabWrapper }} label={text} value={to} disabled={disabled} disableRipple disableFocusRipple />);
  if (disabled) {
    return (<Fragment>
      <Tooltip title="Coming soon" placement="top" enterTouchDelay={0} classes={{ tooltipPlacementTop: classes.tooltips }}>
        <span>
          {inner}
        </span>
      </Tooltip>
    </Fragment>);
  } else if (!to) {
    return
  } else {
    // only item have to and not disabled is accessabled
    return inner;
  }
}

function Menu(props) {
  const lang = props.match.params.Lang;
  const classes = useStyles(theme);
  if (props.mangasData === undefined) {
    props.history.replace("/");
  }
  const menuItems = props.menuItems ? props.menuItems : [];
  return (
    <Fragment>
      <Tabs value={'/' + props.history.location.pathname.split("/")[1] + '/' + props.history.location.pathname.split("/")[2]}
        classes={{ root: `${classes.tabs} hideOnMobile`, indicator: classes.indicator, flexContainer: classes.tabsContainer }}
        onChange={(e, newValue) => props.history.push(newValue)}
        scrollButtons='on'
        variant='scrollable'
        maxWidth='md'>
        <div className={classes.tab} style={{ margin: 0 }} />
        {menuItems.map(MenuItem)}
        <div className={classes.tab} style={{ margin: 0 }} />
      </Tabs>
    </Fragment>
  )
}
function HamburgerMenuItem(props) {
  const classes = useStyles(theme);
  const text = props.text || '';
  const to = props.to;
  const disabled = props.disabled;
  const callback = props.callback || (() => { });
  const inner = (<ListItem button disableRipple disabled={disabled} style={{ marginRight: '2em', marginLeft: '2em' }}>
    <ListItemText>
      {(<Link to={to} onClick={() => callback(false)} className={classes.HamburgerMenuItem}>{text}</Link>)}
    </ListItemText>
  </ListItem>);
  if (disabled) {
    return (<Fragment>
      <Tooltip title="Coming soon" placement="top" enterTouchDelay={0} classes={{ tooltipPlacementTop: classes.tooltips }}>
        <span className={classes.HamburgerMenuItem}>
          {inner}
        </span>
      </Tooltip>
    </Fragment>);
  } else {
    // only item have to and not disabled is accessabled
    return inner;
  }
}
function HamburgerMenu(props) {
  const lang = props.match.params.Lang;
  const classes = useStyles(theme);
  if (props.mangasData === undefined) {
    props.history.replace("/");
  }
  const [menu, setMenu] = useState(false);
  const menuItems = props.menuItems;
  return (<Fragment>
    <HamburgerMenuToggler className={`hideOnDesktop`} callback={(value) => setMenu(value)} menu={menu} />
    <Drawer open={menu} className={`hideOnDesktop`} onClose={() => setMenu(false)}>
      <div style={{ height: 37 }} />
      <List>
        {menuItems.map((item) => (HamburgerMenuItem({ 'callback': setMenu, ...item })))}
      </List>
    </Drawer>
  </Fragment>);
}
function HamburgerMenuToggler(props) {
  const callback = props.callback ? props.callback : () => { };
  const menu = props.menu ? props.menu : false;
  const classes = useStyles(theme);
  return (
    <div className={`hamburgerBar ${menu ? 'triggered' : ''} ${props.className ? props.className : ''}`} style={{ position: menu ? 'sticky' : 'relative' }}>
      <a className='hamburgerIcon' onClick={() => { callback(!menu); }}>
        {menu ? <SVGIcons.CloseMenuIcon className={classes.menuSVGIcon} /> : <SVGIcons.HamburgerMenuIcon className={classes.menuSVGIcon} />}
      </a>
    </div>);
}
/*
function BackgroundCanvas(props) {
  const cav = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(1000);
  const [canvasHeight, setCanvasHeight] = useState(1000);
  const backgroundImgAsset = useRef(null);
  const drawShapes = time => {
    if (cav.current === null) {
      return
    }
    requestAnimationFrame(drawShapes);
    const ctx = cav.current.getContext("2d");
    ctx.clearRect(0, 0, canvasWidth + 1000, canvasHeight + 1000);
    const step = 4000; // 4 sec interval, avarage breath rate of human
    const scaleRate = 0.05; //1.0 +- scaleRate
    for (var x = -100; x < canvasWidth + 200; x += 200) {
      for (var y = -50; y < canvasHeight + 100; y += 100) {
        const scale = (0.3 + Math.sin(Math.PI * 2 * ((time + x / 200 * step / (canvasWidth / 200) + y - canvasHeight / 100 * step / (canvasWidth / 200)) % step) / step) * scaleRate);
        ctx.save();
        ctx.globalAlpha = 0.1 + (scale - 0.3);
        ctx.drawImage(backgroundImgAsset.current, x - ((y % 200)) - 200 / 2 * scale, y - 100 / 2 * scale,
          217 * scale,
          236 * scale);
        //ctx.restore();
      }
    }
  }
  const updateHeightWidth = () => {
    setCanvasWidth(cav.current.clientWidth);
    setCanvasHeight(cav.current.clientHeight);
  };
  useEffect(() => {
    updateHeightWidth();
    window.addEventListener('resize', updateHeightWidth);
    return () => window.removeEventListener('resize', updateHeightWidth);
  }, [])
  return (
    <Fragment>
      <div style={{ position: 'relative', height: '0px', zIndex: 0 }}>
        <canvas ref={cav} className={props.className} width={canvasWidth} height={canvasHeight} />
        <img src="/Asset/topCardBackgroundSingle.png" ref={backgroundImgAsset} style={{ display: 'none' }} onLoad={drawShapes} />
      </div>
    </Fragment>
  )
}*/

export default withRouter(Layout);