import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AdaptiveImageSrcSet from './AdaptiveImageSrcSet';

const useStyles = makeStyles(theme => ({
    overlayImg_outer: {
        width: '100%',
        position: 'relative',
        '&:hover>*, &:hover>*>*': {
            opacity: 1,
        },
    },
    overlayImg_inner: {
        width: "100%",
        height: 'auto'
    },
    overlayImg_inner_fg: {},
    overlayImg_inner_bg: {
        opacity: 0,
        transition: 'opacity 0.1s ease',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
    }
}));


function OverLayImage(props) {
    const { fg, bg, ...others } = props;
    const classes = useStyles();
    return (<div className={classes.overlayImg_outer} key={fg}>
        <AdaptiveImageSrcSet
            className={`${classes.overlayImg_inner} ${classes.overlayImg_inner_fg}`}
            src={fg}
            {...others}
        />
        {bg && <AdaptiveImageSrcSet
            className={`${classes.overlayImg_inner} ${classes.overlayImg_inner_bg}`}
            src={bg}
        />}
    </div>)
}

export default OverLayImage;