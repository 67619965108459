import React, { useState, useEffect, useRef, Fragment } from 'react';
import { makeStyles } from '@material-ui/core';

import { createMuiTheme } from '@material-ui/core/styles';
import { Link as ScrollLink } from 'react-scroll';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#ce1b65'
        },
        secondary: {
            main: "#297b83",
            light: "#4fbece"
        },
        grey: {
            main: '#212121'
        }
    },
    overrides: {
        MuiTab: {
            root: {
                zIndex: 10,
            },
        }
    }
});
const layoutPadding = 24 * 2;
const useStyles = makeStyles(_theme => ({
    maxWidth700: {
        maxWidth: '700px'
    },
    imgComponent: {
        width: '100%',
        flexBasis: '100%',
        flexShrink: 0,
    },
    imgContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    overflowScroll: {
        overflowX: 'scroll',
    },
    justifyContent: {
        justifyContent: 'center',
    },
    oversizePage: {
        marginLeft: -1 * (layoutPadding),
        marginRight: -1 * (layoutPadding),
        width: `calc(100% + ${2 * (layoutPadding)}px)`,
    },
    scrollPadder: {
        width: layoutPadding,
        boxShadow: `inset ${layoutPadding}px 0px ${layoutPadding}px -${layoutPadding}px #FFFFFF`,
        position: 'sticky',
        left: 0,
    },
    scrollPadderRtl: {
        boxShadow: `inset -${layoutPadding}px 0px ${layoutPadding}px -${layoutPadding}px #FFFFFF`,
        right: 0,
    }
}));

function isContentDisplayable(maxWidth = 0, maxHeight = 0, childWidth = 0, childHeight = 0) {
    // input element and child width/height, return if element can displaying all of its content in [horizontal, vertical]
    return [maxWidth >= childWidth, maxHeight >= childHeight];
}

function HorizontalPage(props) {
    const { images, idx, className, ...others } = props;
    const classes = useStyles();
    const [expended, setExpended] = useState(false);
    const [activated, setActivated] = useState(false);
    const selfRef = useRef(null);
    const onWindowResize = useEffect(() => {
        function resetExpended() {
            let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            const [displayingAllX, _] = isContentDisplayable(windowWidth, 0, images.length * selfRef.current.clientWidth);
            setExpended(displayingAllX);
        }
        window.addEventListener('resize', resetExpended)
        resetExpended();
        return (() => window.removeEventListener('resize', resetExpended));
    }, []);
    const onScroll = useEffect(() => {
        function _setActivate() {
            const rect = selfRef.current.getBoundingClientRect();
            if (!activated && rect.top < (window.innerHeight || document.documentElement.clientHeight) / 2) {
                setActivated(true);
                selfRef.current.scrollBy({ left: window.innerWidth * 0.2, behavior: 'smooth' });
            }
        }
        if (!activated) {
            window.addEventListener('scroll', _setActivate);
        }
        return (() => window.removeEventListener('scroll', _setActivate));
    }, [activated]);

    return (<Fragment>
        <div
            className={`${classes.imgContainer} hideScrollBar ${expended ? `${classes.justifyContent}` : `${classes.overflowScroll}`} ${!expended && activated ? 'activated' : ''} ${classes.oversizePage} ${className || ''}`}
            id={`page ${idx}`}
            ref={selfRef}
        >
            {
                images.map((img, _idx) => (
                    <Fragment>
                        <ScrollLink to={`page ${(_idx + 1 >= images.length) ? (idx + 1) : `${idx}.${_idx + 1}`}`}
                            smooth
                            duration={200}
                            className={`${classes.maxWidth700} ${classes.imgComponent} ${classes.maxWidth700}`}
                        >
                            <picture style={{ width: '100%' }}>
                                <source srcset={img} type='image/avif' />
                                <source srcset={img.replace('.avif', '.jpg')} type='image/jpeg' />
                                <img
                                    src={img.replace('.avif', '.jpg')}
                                    id={`page ${idx}.${_idx}`}
                                    style={{ width: '100%' }} />
                            </picture>
                        </ScrollLink>
                    </Fragment>
                ))}
        </div>
    </Fragment>)
};

export default HorizontalPage;