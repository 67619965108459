import React, { useState, Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { chunk } from "lodash";
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { Link } from "react-router-dom";
import OverLayImage from './OverLayImage';

const useStyles = makeStyles(theme => ({
    gridListOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
    },
    gridListTile: {
        padding: theme.spacing(0.5),
        transition: 'opacity 0.3s',
    },
    gridImg: {
        width: "100%",
        height: 'auto',
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.05)",
        '&:hover': {
            boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)"
        }
    },
    overlayPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        width: '100%'
    },
    sidedText: {
        fontSize: 'xx-small'
    },
    caption_odd: {
        textAlign: 'left'
    },
    caption_even: {
        textAlign: 'right'
    }
}));

function Misc(props) {
    const classes = useStyles();
    if (props.miscData === undefined) {
        props.history.replace(`/${props.match.params.Lang}`);
    }
    const data = props.miscData ? props.miscData.map((e, idx) => { return { idx: idx, ...e } }) : null;
    const [coverWeight, setCoverWeight] = useState([]);
    const [pageReady, setPageReady] = useState(false);
    useEffect(() => { data && setCoverWeight(data.map(e => 0)) }, [props.miscData]);
    return (
        <Fragment>
            <Helmet>
                <title>Others - Ovumie</title>
                <meta name="title" content="Others - Ovumie" />
                <meta property="og:title" content="Others - Ovumie" />
                <meta property="twitter:title" content="Others - Ovumie" />
            </Helmet>
            {!pageReady && <div style={{ minHeight: "calc(100vh - 345.533px)", textAlign: 'center', margin: 'auto' }}><CircularProgress /></div>}
            {data && chunk([...data, null], 2).map((data, iOuter) =>
                <Fragment>
                    <Box display="flex" flexWrap="nowrap" flexDirection="row">
                        {data.map((tile, iInter) => {
                            return tile ? (
                                <Fragment>
                                    <Box key={`${tile.name}.${tile.id}`} className={classes.gridListTile} flexShrink={coverWeight[2 * iOuter + iInter]} flexGrow={coverWeight[2 * iOuter + iInter]} flexBasis={0} style={{ opacity: pageReady ? 1 : 0 }}>
                                        <Link to={`/${props.match.params.Lang}/misc/${tile.id}`}>
                                            <OverLayImage
                                                fg={tile.cover}
                                                bg={tile.cover_background}
                                                className={classes.gridImg}
                                                onLoad={({ target: img }) => {
                                                    setCoverWeight(coverWeight.map((e, idx) => idx === (2 * iOuter + iInter) ? (10000 * img.naturalWidth / img.naturalHeight) : e));
                                                    if (coverWeight.filter(e => e === 0).length < 2 || true) {
                                                        setPageReady(true);
                                                    }
                                                }}
                                            />
                                        </Link>
                                    </Box>
                                </Fragment>
                            ) : <div style={{ flexGrow: coverWeight[coverWeight.length - 1] }} />
                        })}
                    </Box>
                </Fragment>)
            }
        </Fragment>
    );
}

function SidedText(props) {
    const { children, className, ...others } = props;
    const classes = useStyles();
    return (<Fragment>
        <div className={`${classes.sidedText} ${className || ''}`}>
            {children}
        </div>
    </Fragment>)
}

export default Misc;
