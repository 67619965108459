import React, { useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import { scroller } from 'react-scroll';

const useStyles = makeStyles(theme => ({
    videoContainer: {
        padding: '32px',
        position: 'relative'
    },
    videoTitle: {
        margin: '16px 0',
        fontFamily: "'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif",
        fontWeight: 'bold',
        transition: 'color 0.2s ease',
        fontSmooth: 'always'
    },
    nowPlayingTitle: {
        color: '#ce1b65'
    },
    videoDescription: {
        margin: '16px 0',
        fontFamily: "'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif",
        fontSmooth: 'always'
    },
    videoOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer'
    }
}));

function Animation(props) {
    const classes = useStyles();
    if(props.animationData === undefined){
        props.history.replace(`/${props.match.params.Lang}`);
    }
    const data = props.animationData?props.animationData.map((e, idx) => { return { idx: idx, ...e } }):null;
    const [nowPlaying, setNowPlaying] = useState(0);
    return (
        <Fragment>
            <Helmet>
                <title>Animation - Ovumie</title>
                <meta name="title" content="Animation - Ovumie" />
                <meta property="og:title" content="Animation - Ovumie" />
                <meta property="twitter:title" content="Animation - Ovumie" />
            </Helmet>
            {data && data.map((tile, idx) => {
                return (<Fragment>
                    {idx > 0 && <Divider />}
                    <div name={`video_${idx}`}
                        className={classes.videoContainer}>
                        <Typography variant="h5" className={`${classes.videoTitle} ${idx === nowPlaying ? classes.nowPlayingTitle : ''}`}>{tile.name}</Typography>
                        <ReactPlayer url={tile.video}
                            onStart={() => setNowPlaying(idx)}
                            onEnded={() => {
                                scroller.scrollTo(`video_${idx + 1}`, { duration: 200, smooth: 'ease' });
                                setNowPlaying(idx + 1);
                            }}
                            playing={idx === nowPlaying}
                            controls
                            light={idx !== nowPlaying}
                            width='100%'
                            height='calc( ( 100vw - 48px - 64px ) / 16 * 9)'
                            style={{ maxHeight: '468px' }}
                        />
                        {tile.description.map((line) =>
                            <Typography className={classes.videoDescription}>{line}</Typography>
                        )}
                    </div>
                </Fragment>);
            })}
            {!data && <div style={{ minHeight: "calc(100vh - 345.533px - 48px)" }}></div>}
        </Fragment>
    );
}

export default Animation;
