import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    aboutContent: {
        padding: theme.spacing(2)
    }
}));

function About(props) {
    const classes = useStyles();
    if(props.aboutData === undefined){
        props.history.replace(`/${props.match.params.Lang}`);
        return (<Fragment/>);
    }
    return (
        <Fragment>
            <Helmet>
                <title>About - Ovumie</title>
                <meta name="title" content="Books &amp; Goods - Ovumie" />
                <meta property="og:title" content="Books &amp; Goods - Ovumie" />
                <meta property="twitter:title" content="Books &amp; Goods - Ovumie" />
            </Helmet>
            <ReactMarkdown linkTarget='_blank' className={`mdContent aboutContent ${classes.aboutContent}`} source={props.aboutData} />
            <div style={{ clear: 'both' }}></div>
        </Fragment>
    );
}

export default About;
