import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    contactContent: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: '#212121'
    }
}));

function Contact(props) {
    const classes = useStyles();
    if (props.contactData === undefined) {
        props.history.replace(`/${props.match.params.Lang}`);
        return (<Fragment />);
    }
    return (
        <Fragment>
            <Helmet>
                <title>Contact - Ovumie</title>
                <meta name="title" content="Contact - Ovumie" />
                <meta property="og:title" content="Contact - Ovumie" />
                <meta property="twitter:title" content="Contact - Ovumie" />
            </Helmet>
            <div className={`mdContent contactContent ${classes.contactContent}`}>
                <p style={{ fontSize: 30 }}>
                    ovumie@gmail.com
                </p>
                <img src="/Asset/heartGun.png" width="75%" />
                <div style={{ fontSize: 24 }}>
                    <p>
                        <a href="https://www.instagram.com/ovumie/" target="_blank">Instagram</a>
                    </p>
                    <p>
                        <a href="https://www.facebook.com/huihui2017/" target="_blank">Facebook</a>
                    </p>
                    <p>
                        <a href="https://twitter.com/ovumie" target="_blank">Twitter</a>
                    </p>
                </div>
            </div>
            <div style={{ clear: 'both' }}></div>
        </Fragment>
    );
}

export default Contact;
