import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Button, Grid } from '@material-ui/core';
import { MdArrowForward, MdArrowBack, MdCheck, MdClose } from 'react-icons/md';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import { Helmet } from 'react-helmet';
import HorizontalPage from './HorizontalPage';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    gridListOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
    },
    gridListTile: {
        padding: theme.spacing(0.5),
    },
    gridImg: {
        width: "100%",
        height: 'auto',
    },
    wideWrapper: {
        overflowX: 'auto'
    },
    wideInnerWrapper: {
        display: 'flex',
        flexDirection: "row-reverse",
        flexWrap: 'nowrap',
        justifyContent: "center"
    },
    bottomNavigation: {
        marginTop: theme.spacing(2)
    },
    rateBlocker: {
        position: "absolute",
        left: "50%",
        width: "100vw",
        maxWidth: "500px",
        zIndex: 15,
        transform: "translateX(-50%)",
        padding: theme.spacing(2)
    },
    maxWidth700: {
        maxWidth: '700px'
    },
    blur: {
        filter: 'blur(15px)',
    },
}));

function MangaReader(props) {
    const classes = useStyles();
    const data = props.mangasData;
    const [firstTime, setFirstTime] = useState(false); //assume that you are not first time...?
    useEffect(_ => {//first time?
        setFirstTime(true);//we got a first time!
    }, [])
    useEffect(_ => {
        window.scrollTo(0, 0);
    }, [props.history.location.pathname]);
    if (data && (data.length <= parseInt(props.match.params.page))) {
        props.history.replace(`/${props.match.params.Lang}`);
        return (<Fragment />);
    }
    const page = parseInt(props.match.params.page);
    return (
        <Fragment>
            {firstTime && <div className={classes.rateBlocker}>
                {
                    props.match.params.Lang === 'zh-tw' ? <Fragment>
                        <img src="/Asset/R18_zh-tw.png" style={{ width: "100%" }} />
                        <Grid container spacing={2} justify="center">
                            <Grid item><Button color="secondary" variant="contained" onClick={() => setFirstTime(false)}><MdCheck />&nbsp;我已滿 18 歲</Button></Grid>
                            <Grid item><Button color="primary" variant="contained" onClick={() => props.history.push("/")}><MdClose />&nbsp;我未滿 18 歲</Button></Grid>
                        </Grid>
                    </Fragment> : <Fragment>
                        <img src="/Asset/R18_en.png" style={{ width: "100%" }} />
                        <Grid container spacing={2} justify="center">
                            <Grid item><Button color="secondary" variant="contained" onClick={() => setFirstTime(false)}><MdCheck />&nbsp;I'm 18 or older</Button></Grid>
                            <Grid item><Button color="primary" variant="contained" onClick={() => props.history.push("/")}><MdClose />&nbsp;I'm 17 or under</Button></Grid>
                        </Grid>
                    </Fragment>
                }
            </div>}
            {data &&
                <Fragment>
                    <Helmet>
                        <title>{`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode} - Ovumie`}</title>
                        <meta name="title" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode} - Ovumie`} />
                        <meta name="description" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode}`} />

                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode} - Ovumie`} />
                        <meta property="og:description" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode}`} />
                        <meta property="og:image" content={`${data[parseInt(props.match.params.page)].fullColorCover}`} />

                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content={window.location.href} />
                        <meta property="twitter:title" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode} - Ovumie`} />
                        <meta property="twitter:description" content={`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode}`} />
                        <meta property="twitter:image" content={`${data[parseInt(props.match.params.page)].fullColorCover}`}></meta>
                    </Helmet>
                    <Container className={`${classes.maxWidth700} ${firstTime ? classes.blur : ""}`} style={{}}>
                        {page > 0 && <Box><Link to={`/${props.match.params.Lang}/manga/${page - 1}/`}><Button ><MdArrowBack />&nbsp;Previous Episode</Button></Link></Box>}
                        <Typography variant="h3" style={{ marginTop: '16px', fontFamily: "'GenSenMaruGothicTW', 'Noto Sans TC', sans-serif", fontSize: "36px", fontWeight: 'bold', textAlign: "center", lineHeight: "150%" }}>{`${data[parseInt(props.match.params.page)].series} EP.${data[parseInt(props.match.params.page)].episode}`}</Typography>
                        {data[page].imgs.map((img, idx) => {
                            if (Array.isArray(img)) {
                                return (<Fragment>
                                    <HorizontalPage
                                        images={img}
                                        idx={idx}
                                    />
                                </Fragment>)
                            } else {
                                return (<Fragment>
                                    <ScrollLink
                                        to={`page ${idx + 1}`}
                                        duration={200}
                                        smooth>
                                        <picture
                                            key={`picture ${page - 1} page ${idx}`}>
                                            <source srcSet={img} type='image/avif' />
                                            <source srcSet={img.replace('.avif', '.jpg')} type='image/jpeg' />
                                            <img
                                                src={img.replace('.avif', '.jpg')}
                                                alt={`page ${idx}`}
                                                key={`${page - 1} page ${idx}`}
                                                id={`page ${idx}`}
                                                className={`${classes.gridImg}`} />
                                        </picture>
                                    </ScrollLink>
                                </Fragment>)
                            }
                        })}
                        <Box display="flex" justifyContent="space-between" className={classes.bottomNavigation}>
                            <Box><Link to={`/${props.match.params.Lang}/manga/`}><Button><MdArrowBack />&nbsp;Back to Menu</Button></Link></Box>
                            {page + 1 < data.length && <Box><Link to={`/${props.match.params.Lang}/manga/${page + 1}/`}><Button color="secondary" variant="contained">Next Episode&nbsp;<MdArrowForward /></Button></Link></Box>}
                        </Box>
                    </Container>
                </Fragment>
            }
            {!data && <div style={{ minHeight: "calc(100vh - 345.533px)" }}></div>}
        </Fragment>
    );
}

export default MangaReader;
