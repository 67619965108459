import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Manga from './Manga';
import Home from './Home';
import Illustration from './Illustration';
import { Helmet } from 'react-helmet';
import './App.css';
import Layout from './Layout';
import MangaReader from './MangaReader';
import Booth from './Booth';
import About from './About';
import Animation from './Animation';
import Misc from './Misc';
import Contact from './Contact';
import MiscReader from './MiscReader';

function App(props) {
  useEffect(() => { document.getElementById("loadingoverlay").classList.add("hideloadingoverlay"); }, []);
  return (
    <Fragment>
      <Helmet>
        <title>Ovumie</title>
        <meta name="title" content="Ovumie" />
        <meta name="description" content="Ovumie.com" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ovumie.com/" />
        <meta property="og:title" content="Ovumie" />
        <meta property="og:description" content="Ovumie.com" />
        <meta property="og:image" content="/favicon-196.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://ovumie.com/" />
        <meta property="twitter:title" content="Ovumie" />
        <meta property="twitter:description" content="Ovumie.com" />
        <meta property="twitter:image" content="/favicon-196.png"></meta>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/:Lang/">
            <Layout>
              <PassingPropsReact />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </Fragment>
  )
}

/*
function LangRedirct(props) {
  const [languageSetting, setLanguageSetting] = useState((navigator.language || navigator.userLanguage).toLowerCase());
  const [languageSet, setLanguageSet] = useState(false);
  return (
    <Fragment>
      {languageSet ?
        <Redirect to={`/${languageSetting}`} />
        :
        <h2>loading...</h2>
      }
    </Fragment>
  );
}
*/

function PassingPropsReact(props) {
  return (
    <Fragment>
      <Route exact path="/:Lang/" render={(_props) => <Redirect to={`/${_props.match.params.Lang}/illustation`} />} />
      <Route path="/:Lang/manga">
        <Route exact path="/:Lang/manga" render={(_props) => <Manga mangasData={props.mangasData} {..._props} />} />
        <Route path="/:Lang/manga/:page" render={(_props) => <MangaReader mangasData={props.mangasData} {..._props} />} />
      </Route>
      <Route path="/:Lang/illustation" render={(_props) => <Illustration illustrationData={props.illustrationData} {..._props} />} />
      <Route path="/:Lang/booth" render={(_props) => <Booth boothData={props.boothData} boothBanner={props.boothBanner} {..._props} />} />
      <Route path="/:Lang/about" render={(_props) => <About aboutData={props.aboutData} {..._props} />} />
      <Route exact path="/:Lang/misc" render={(_props) => <Misc miscData={props.miscData} {..._props} />} />
      <Route path="/:Lang/misc/:page_key" render={(_props) => <MiscReader miscData={props.miscData} {..._props} />} />
      <Route path="/:Lang/contact" render={(_props) => <Contact contactData={props.contactData} {..._props} />} />
    </Fragment>
  )
}

export default App;
