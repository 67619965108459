import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ReactSwipe from 'react-swipe';
import './Home.css';

const useStyles = makeStyles(theme => ({
    langChoiceOuter: {
        padding: '8px 16px',
        border: '1.5px solid white',
        borderRadius: '1.5px',
        width: 'auto',
        margin: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.7)",
        boxShadow: "0 0 16px 1.5px black",
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        '&:visited': {
            color: 'white',
            textDecoration: 'none',
        },
        '&:link': {
            color: 'white',
            textDecoration: 'none',
        },
        '&:active': {
            color: 'white',
            textDecoration: 'none',
        },
        '&:hover': {
            color: '#4fbece',
            textDecoration: 'none',
        }
    },
    swiper: {
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
    },
    swipee: {
        width: '100vw !important',
        height:'100vh',
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    backgroundOverlay:{
        background: 'url("/Asset/topCardBackground.png")',
        opacity: 0.8,
        width: '100vw',
        height:'100vh',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 5,
    }
}));


function Home() {
    const classes = useStyles();
    const [data, setData] = useState(null);
    let reactSwipeEl;
    const continueSwipe = ()=>{
        if(reactSwipeEl && reactSwipeEl !== null){
            reactSwipeEl.next();
            setTimeout(continueSwipe, 5000);
        }
    }
    useEffect(_ => {
        fetch(`/Pages/FrontpageIllustration.json`, { cache: "no-cache" })
            .then(response => response.json())
            .then(_data => setData(_data))
    }, []);
    return (
        <Fragment>
            <div className={classes.langChoiceOuter}>
                <Link className={classes.link} to='/zh-tw/'>中文</Link> | <Link className={classes.link} to='/en/'>Eng</Link>
            </div>
            {data && <Fragment>
                <ReactSwipe
                className={classes.swiper}
                swipeOptions={{speed: 300}}
                ref={el => {reactSwipeEl = el; setTimeout(continueSwipe, 5000);}}>
                    {data.map(img=><div style={{backgroundImage: `url(${img.img})`}} className={classes.swipee}/>)
                    }
                </ReactSwipe>
            </Fragment>
            }
        </Fragment>
    );
}

export default Home;
