import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { Link } from "react-router-dom";
import ImgsViewer from 'react-images-viewer';
import { Helmet } from 'react-helmet';
import { chunk } from "lodash";

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    gridListOuter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
    },
    gridListTile: {
        padding: theme.spacing(0.5),
        transition: 'opacity 0.3s',
    },
    gridImg: {
        width: "100%",
        height: 'auto',
        boxShadow: "0 0 15px 0 rgba(0,0,0,0.05)",
        '&:hover': {
            boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)"
        }
    }
}));

function Illustration(props) {
    const classes = useStyles();
    const [coverWeight, setCoverWeight] = useState([]);
    const [galleryDisplay, setGalleryDisplay] = useState(false);
    const [currentImg, setCurrentImg] = useState("");
    const [pageReady, setPageReady] = useState(false);
    useEffect(() => { props.illustrationData && setCoverWeight(props.illustrationData.map(e => 0)) }, [props.illustrationData]);
    return (
        <Fragment>
            <Helmet>
                <title>Illustration - Ovumie</title>
                <meta name="title" content="Illustration - Ovumie" />
                <meta property="og:title" content="Illustration - Ovumie" />
                <meta property="twitter:title" content="Illustration - Ovumie" />
            </Helmet>
            {!pageReady && <div style={{ minHeight: "calc(100vh - 345.533px)", textAlign: 'center', margin: 'auto' }}><CircularProgress /></div>}
            {props.illustrationData &&
                <Fragment>
                    <ImgsViewer
                        imgs={props.illustrationData.map(e => { return { src: e.img, caption: `${e.name} - ${e.description}` } })}
                        currImg={currentImg}
                        isOpen={galleryDisplay}
                        onClickPrev={e => setCurrentImg((currentImg - 1) % props.illustrationData.length)}
                        onClickNext={e => setCurrentImg((currentImg + 1) % props.illustrationData.length)}
                        onClose={e => setGalleryDisplay(false)}
                        backdropCloseable
                        closeBtnTitle="Exit"
                        leftArrowTitle="Backward"
                        rightArrowTitle="Forward"
                    />
                    {chunk(props.illustrationData, 2).map((data, iOuter) =>
                        <Fragment>
                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                {data.map((tile, iInter) => {
                                    return (
                                        <Fragment>
                                            <Box key={tile.name} className={classes.gridListTile} flexShrink={coverWeight[2 * iOuter + iInter]} flexGrow={coverWeight[2 * iOuter + iInter]} flexBasis={0} style={{ opacity: pageReady ? 1 : 0 }}>
                                                <Link onClick={e => {
                                                    setCurrentImg(2 * iOuter + iInter);
                                                    setGalleryDisplay(true);
                                                }}>
                                                    <img
                                                        src={tile.img}
                                                        alt={tile.name}
                                                        className={classes.gridImg}
                                                        onLoad={({ target: img }) => {
                                                            setCoverWeight(coverWeight.map((e, idx) => idx === (2 * iOuter + iInter) ? (10000 * img.naturalWidth / img.naturalHeight) : e));
                                                            if (coverWeight.filter(e => e === 0).length < 2) {
                                                                setPageReady(true);
                                                            }
                                                        }} />
                                                </Link>
                                            </Box>
                                        </Fragment>
                                    )
                                })}
                            </Box>
                        </Fragment>)}
                </Fragment>}
        </Fragment>
    );
}

export default Illustration;
